.applyForSchhol__wrapper {
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.applyForSchhol__content {
  height: fit-content;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  width: 70% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 2rem;
}

.applyForSchhol__wrapper h1 {
  margin: 2rem 0rem;
  font-size: 3rem;
  font-weight: 500;
  color: #14a800;
  text-align: center;
  text-transform: capitalize;
}

.applyForSchhol__content form {
  width: 100%;
  display: flex;
  flex-direction: column;
    gap: 1.7rem;
}

.applyForSchhol__wrapper .form__wrapper {
  width: 100%;
  gap: 2rem;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.applyForSchhol__wrapper .form__group {
  display: flex;
  flex-direction: column;
}

.form__group label {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.4rem;
}

.applyForSchhol__wrapper button {
  align-items: center;
  justify-content: space-between;
  background-color: #14a800;
  color: #fff;
  margin-top: .4rem;
  text-align: center;
  border-radius: 5px;
  padding: 1rem 4rem;
  font-weight: 600;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  width: 30%;
}

@media (max-width: 768px) {
  .applyForSchhol__content {
    width: 90% !important;
  }
}

@media (max-width: 568px) {
  .applyForSchhol__wrapper {
    height: fit-content;
    margin: 2rem 0rem;
  }
  .applyForSchhol__content {
    width: 100% !important;
    padding: 1rem;
  }

  .applyForSchhol__wrapper h1 {
    font-size: 2rem;
  }
  .applyForSchhol__wrapper .form__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .form__group input,
  .form__group select {
    width: 100%;
  }

  .applyForSchool button {
    width: 100%;
    margin-top: 0rem;
  }
}
