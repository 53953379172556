.forgotPassword{
    background-color: rgb(245,244,244);

  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.forgotPassword__wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
}

.forgetPassword__content{
    height: fit-content;
    padding: 2rem;
 
      background-color: #fff;
      border-radius: .5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 35%;
}

.forgetPassword__content img{
   height: fit-content;
  width: 130px;
  object-fit: cover;
  margin-top: -1rem;
}

.forgetPassword__content h2{
    margin: 1rem 0rem;
    color: #14a800;
}

.forgetPassword__content form{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.forgetPassword__content .form-group{
     width: 100% !important;
}

.forgetPassword__content .form-control{
        padding: .5rem;
}

.forgetPassword__content .btn{
     width: 100% !important;
       background-color: #14a800;
       color: #fff;
       font-weight: 500;
       padding: .5rem 0rem;
}

.forgetPassword__content .btn:hover{
     width: 100% !important;
       background-color: #14a800 !important;
       color: #fff !important;
       font-weight: 500 !important;
       padding: .5rem 0rem;
}

.forgetPassword__content .forgetPassword__text{
    display: flex;
    align-items: center;
        margin: 1rem 0rem;
        gap: .5rem;
}

.forgetPassword__content svg{
    color: #14a800;
    font-size: 7rem;
}

.successMail p{
    margin: 2rem 0rem;
    text-align: center;
}

.forgetPassword__content a{
    text-decoration: none;
     text-transform: capitalize;
}

@media (max-width:786px){
    .forgetPassword__content{

      width: 70%;
}
}


@media (max-width:586px){
    .forgetPassword__content{

      width: 100%;
}
}


.login__wrapper .f-button{
margin-top: 10px;
}
