.navbar_up {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.navbar_up_right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.navbar_up_right .navbar_up_right-content {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.navbar_up_right-content .icon {
  border: 2px solid #14a800;
  padding: 0.3rem;
  font-size: 2rem;
  color: #14a800;
}

.navbar_up-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.navbar_up-text p:last-child {
  font-weight: 700;
  text-transform: none;
}
/* NAVBAR BOTTOM */

.navbar {
  background-color: #14a800;
  max-width: 100%;
  top: 0 !important;
  position: sticky !important;
  z-index: 100;
}

.navbar_wrapper {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.navbar_up .logo {
  display: flex;
}

.logo img {
  height: fit-content;
  width: 130px;
  object-fit: cover;
}

.navbar .logo {
  color: #fff;
  display: none;
}

.logo img {
  height: 3.5rem;
}

.navbar__right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar__right .nav-links {
  display: flex;
  align-items: center;
  gap: 1rem;
  list-style: none;
  margin-bottom: 0rem;
  padding-left: 0rem !important;
}

.nav-links li a {
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
}

li a.active {
  background-color: #ff9600 !important;
  font-weight: 700;
  padding: 1rem;
}

.navbar_right button {
  background-color: #1870b2;
  border: none;
  padding: 0.5rem 0.4rem;
  color: #fff;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
}

.menu-icon {
  display: none;
}

.navbar_wrapper .btn {
  background-color: #2c97eb;
  border: none;
  padding: 0.5rem 0.8rem;
  color: #fff;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.navbar_wrapper .btn:hover {
  background-color: #2c97eb !important;
  color: #fff !important;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.btn-wrapper p {
  margin-bottom: 0rem;
  color: #fff;
  font-weight: 500;
}

.btn-wrapper span {
  font-weight: bold;
}

.currentUser_logout {
  text-decoration: none;
  background-color: #ff9600 !important;
  font-weight: 700;
  padding: 1rem;
  color: #fff;
  cursor: pointer;
}

.currentUser_logout:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .navbar_up {
    display: none;
  }

  .navbar {
    background-color: #fff;
    max-width: 100%;
    top: 0;
    position: sticky;
    z-index: 100;
     background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
  }

  .navbar .logo {
    color: #fff;
    display: block;
  }

  .navbar__right {
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background-color: #14a800;
    z-index: 1000;
  }

  .nav-links li {
    margin-top: 1.5rem;
  }

  .navbar__right.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 100;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .menu-icon {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    color: #000;
  }

  .navbar__right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: unset;
  }

  .navbar__right .nav-links {
 width: 100%;
    padding-left: 2rem !important;
}

 .btn-wrapper{
    display: flex;
    /* flex-direction: column; */
  }
}
