.applyForFunding__wrapper{
  height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.applyForFunding__content {
  height: fit-content;
box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  width: 70% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   border-radius: .5rem;
  padding: 2rem;
}

.applyForFunding__content form{
    width: 100%;
}

.applyForFunding__content h1 {
  margin: 2rem 0rem;
  font-size: 3rem;
  font-weight: 500;
  color: #14a800;
  text-align: center;
  text-transform: capitalize;
}

.form__wrapper {
  width: 100%;
  gap: 2rem;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.form__wrapper .form__group {
  display: flex;
  flex-direction: column;
}

.form__group label, .textarea_wrapper label{
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.applyForFunding__content .textarea_wrapper{
    margin-top: 2rem;
}

.applyForFunding__content button {
  align-items: center;
  justify-content: space-between;
  background-color: #14a800;
  color: #fff;
  margin-top: 1rem;
  text-align: center;
  border-radius: 5px;
  padding: 1rem 4rem;
  font-weight: 600;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  /* width: 30%; */
}

@media (max-width: 768px) {

.applyForFunding__content {
  width: 90% !important;
}
}

@media (max-width: 568px) {

  .applyForFunding__wrapper{
    height: fit-content;
    margin: 2rem 0rem;
  }

 .applyForFunding__content {
  width: 100% !important;
  padding: 1rem;
}

.applyForFunding__content h1 {
  font-size: 2rem;

}

  .form__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .form__group input,
  .form__group select {
    width: 100%;
  }

  .applyForFunding__content button {
    width: 100%;
  }
}
