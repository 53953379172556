

.file .file__content {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em,
    rgb(90 125 188 / 5%) 0px 0.25em 1em;
  padding: 2rem;
  border-radius: 0.5rem;
  transition: all 0.9s ease-in-out !important;
}

.file__content .form-select {
  cursor: pointer;
}

.file__content .btn {
  width: 140px;
}

.file__wrapper .yes {
  color: #14a800;
  font-size: 1.2rem;
    margin-left: 1rem;
}
.file__wrapper .no{
  color: red;
  font-size: 1.2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.file__wrapper .fecthAllFile__wrapper {
  width: 75%;
}

.file__wrapper  .btn{
    background-color: #14a800 !important;
    color: #fff !important;
    padding: .7rem 1rem !important;
    font-weight: 500 !important;
    transition: all 0.5s ease-in-out;
}

.file__wrapper  .btn:hover{
  background-color: #14a800 !important;
    color: #fff !important;
    padding: .7rem 1rem !important;
    font-weight: 500 !important;
}
.file__wrapper  .btn:active{
  background-color: #14a800 !important;
    color: #fff !important;
    padding: .7rem 1rem !important;
    font-weight: 500 !important;
}

.fecthAllFile__wrapper tbody th{
  text-transform: capitalize;
}



.fecthAllFile__wrapper .download svg{
cursor: pointer;
color: #0d6efd;
    margin-left: 2rem;
}

@media (max-width: 768px) {
  .file__wrapper .fecthAllFile__wrapper {
    width: 80%;
  }

  .file .file__content {
  width: 80%;
  
}
}

@media (max-width: 568px) {
  .file__wrapper .fecthAllFile__wrapper {
    width: 100%;
  }

    .file .file__content {
  width: 100%;
   padding: 1rem;
}
}
