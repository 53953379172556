.contact .contact__shadow {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin: 4rem auto 4rem auto;
  width: 70% !important;
}

.contact h4 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.25;
  color: #14a800;
  margin-bottom: 1.8rem;
}

.contact form {
  background-color: #fff;
  border-radius: 5px;
  padding: 0 20px;
  padding: 30px;
}

.contact form div {
  display: flex;
}
.contact textarea {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
  background: none;
  padding: 10px;
}
.contact input {
  margin-bottom: 20px;
  margin-right: 10px;
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
  outline: none;
  background: none;
}

.contact button {
  border-radius: 5px;
  padding: 17px 30px;
  background: #14a800;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .contact .contact__shadow {
    width: 80% !important;
  }
  .contact h4 {
    width: 100%;
    margin-bottom: 30px;
  }
  .contact form div {
    flex-direction: column;
  }
  .contact input {
    width: 100%;
  }
}

@media screen and (max-width: 568px){
  .contact .contact__shadow {
    width: 100% !important;
  }

.contact h4 {
  font-size: 2rem;

}
}
