.home {
  background-image: linear-gradient(
      92.75deg,
      rgba(0, 0, 0, 0.6) 37.33%,
      rgba(0, 0, 0, 0.03) 77.73%
    ),
    url(../../asset/banner1.jpeg);
  width: 100%;
  height: 70vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
}

.home .home_wrapper {
  color: #fff;
}

.home_wrapper h1 {
  text-transform: capitalize;
  font-size: 4rem;
  line-height: 1.25;
  font-weight: bold;
  width: 60%;
}

.home_wrapper p {
  font-weight: 500;
  line-height: 1.5rem;
  width: 50%;
  margin-top: 1rem;
}

.home_button-wrapper {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}

.home_button-wrapper button {
  border: 1px solid #fff;
  padding: 0.625rem;
  gap: 0.625rem;
  width: 7.688rem;
  /* height: 2.5rem; */
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
}

.home_button-wrapper button:first-child {
  border: 1px solid #ff9600;
  background-color: #ff9600;
}

.home_button-wrapper a,
.home_button-wrapper a:hover {
  text-decoration: none;
  color: #fff;
}

.home_about {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  gap: 1rem;
  margin-top: -1rem;
  justify-content: space-evenly;
  align-self: center;
}

.home_about .home_about-content {
  width: 21.875rem;
  height: 16.125rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 1rem;
  border-radius: 3px;
}

.home_about .home_about-content:first-child {
  background-color: #ff9601;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.03) 0px 1px 3px -1px;
}

.home_about .home_about-content:nth-child(2) {
  background-color: #2c97eb;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.03) 0px 1px 3px -1px;
}

.home_about .home_about-content:nth-child(3) {
  background-color: #f0bc00;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.03) 0px 1px 3px -1px;
}

.home_about-content .icon {
  font-size: 4rem;
}

.home_about-content h4 {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.813rem;
  margin-top: 1rem;
}

.home_about-content p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
}

.sectionTwo {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  gap: 4rem;
}

.sectionTwo_left {
  width: 50%;
  margin-top: 15rem;
}

.sectionTwo_left img {
  height: 800px;
  width: 100%;
  object-fit: cover;
}

.sectionTwo_right {
  width: 50%;
  margin-top: 8rem;
}

.sectionTwo_right > h4 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.25;
  color: #14a800;
  text-align: center;
  margin-bottom: 1.8rem;
}

.sectionTwo_flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 4rem;
}

.sectionTwo_flex .flex {
  background-color: #dbf7f7;
  padding: 1.2rem;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.03) 0px 1px 3px -1px;
  cursor: pointer;
}

.flex h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0.6rem;
  text-transform: capitalize;
}

.flex p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #959596;
}

.sectionTwo_right-bottom h4 {
  font-size: 2.5rem;
  line-height: 1.25;
  /* line-height: 40px; */
  color: #14a800;
  width: 100%;
  font-weight: 700;
}

.sectionTwo_right-bottom p {
  color: #959596;
  line-height: 20px;
  margin-top: 1rem;
  font-size: 16px;
}

.sectionThree {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../asset/banner1.jpeg);
  width: 100%;
  height: 161px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0rem;
}

.sectionThree .sectionThree-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.sectionThree-wrapper h4 {
  color: #fff;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
}

.sectionThree-wrapper button {
  border: 1px solid #fff;
  padding: 0.625rem;
  gap: 0.725rem;
  width: 10.688rem;
  height: 2.5rem;
  border-radius: 3px;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  border-radius: 1.25rem;
}

.sectionFour {
  height: 652px;
}

.sectionFour .sectionFour-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0rem;
  height: 100%;
  width: 100%;
}

.sectionFour .sectionFour-wrapper-text {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.sectionFour-wrapper-text h4 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 48px;
  color: #14a800;
}

.sectionFour-wrapper-text p {
  color: #959596;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 434px;
}

.sectionFour-wrapper-text button {
  border: 1px solid #14a800;
  padding: 0.625rem;
  gap: 0.725rem;
  width: 10.688rem;
  height: 2.5rem;
  border-radius: 3px;
  color: #14a800;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  border-radius: 1.25rem;
  margin-top: 0.5rem;
}

.sectionFour-wrapper .sectionFour-wrapperImg {
  background-image: url(../../asset/UnionBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem;
}

/******************** MEDIA QUERY ********************/

@media (max-width: 768px) {
  .home_wrapper h3 {
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;
    width: 80%;
  }

  .home_wrapper p {
    font-weight: 500;
    line-height: 1.5rem;
    margin-top: 1rem;
    width: 80%;
  }

  .home_wrapper h1 {
    text-transform: capitalize;
    font-size: 4rem;
    line-height: 1.25;
    font-weight: bold;
    width: 100%;
  }

  .home_about .home_about-content {
    width: 18rem;
    height: 16.125rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    padding: 1rem;
    border-radius: 3px;
  }

  .sectionTwo {
    flex-direction: column;
  }

  .sectionTwo_left {
    width: 100%;
    margin-top: 5rem;
  }

  .sectionTwo_right {
    width: 100%;
    margin-top: 4rem;
  }

  .sectionTwo_right > h4 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    color: #14a800;
    text-align: center;
    margin-bottom: 1.8rem;
  }

  .sectionTwo_flex {
    margin-bottom: 2rem;
  }

  .sectionTwo_right-bottom h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #14a800;
    width: 80%;
  }

  .sectionTwo_right-bottom p {
    color: #959596;
    line-height: 24px;
    margin-top: 1rem;
    font-size: 15px;
  }

  .sectionThree-wrapper h4 {
    font-size: 30px;
    line-height: 40px;
  }

  .sectionFour {
    margin-top: 3rem;
  }

  .sectionFour-wrapper {
    flex-direction: column;
  }

  .sectionFour-wrapperImg {
    margin-top: 2rem;
  }
}

@media (max-width: 568px) {
  .home_wrapper h1 {
    text-transform: capitalize;
    font-size: 2rem;
    line-height: 1.25;
    font-weight: bold;
    width: 100%;
  }

  .home_about {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    gap: 1rem;
    margin-top: -3rem;
    justify-content: space-evenly;
    align-self: center;
  }

  .sectionTwo_right {
    width: 100%;
    margin-top: 1.5rem;
  }

  .sectionTwo_left img {
    height: 500px;
    width: 100%;
    object-fit: contain;
  }

  .sectionTwo_flex {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 3rem;
  }

  .sectionTwo_right-bottom h4 {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #14a800;
    width: 100%;
  }

  .sectionThree .sectionThree-wrapper {
    margin: 1rem 0rem;
  }

  .sectionThree {
    height: 180px;
  }

  .sectionThree .sectionThree-wrapper {
    flex-direction: column;
  }

  .sectionThree-wrapper button {
    padding: 0.525rem;
  }

  .sectionFour {
    height: auto;
    overflow-x: hidden;
  }

  .sectionFour-wrapper-text h4 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 48px;
    color: #14a800;
  }

  .sectionFour-wrapperImg {
    display: none;
  }

  .sectionFour-wrapper {
    display: block !important;
  }

  .sectionFour-wrapper-text button {
    padding: 0.525rem;
  }

  .sectionFour-wrapper-text p {
    color: #959596;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    width: fit-content;
  }
}
