.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
  width: 100%;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(39, 134, 42, 0.665);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}

@media screen and (max-width: 800px) {


  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}

@media screen and (max-width: 586px) {

.back .container {
    padding: 40px 0;
     width: 90%;
}
}