
.signUp__right .alert{
    margin: -1.5rem 0rem -.3rem 0rem;
  transition: all 0.5s ease;

}

.signUp__right form {
  width: 450px !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.signUp__right input,
.signUp__right select {
  width: 90%;
  font-size: 1rem;
  outline: none;
  border: none;
  height: 100%;
}

.signUp__right .form-group {
  display: flex;
  align-items: center;
  gap: .5rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  background-color: #fff;
  padding: 1rem 0.5rem;
  border-radius: 5px;
}

.form-group svg {
  font-size: 1.5rem;
}

.signUp__right-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.signUp__right-bottom button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #14a800;
  color: #fff;
  gap: 0.5rem;
  width: 150px;
  margin-top: 1rem;
  border-radius: 5px;
  padding: 1rem;
  font-weight: 600;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}

.signUp__right-bottom a {
  color: #111;
  text-decoration: none;
}

.signUp__right-bottom a:hover{
    color: #000;
}

.signUp__right-bottom p{
    margin-bottom: 0rem;
}

.signUp__right .link {
  display: none;
}


@media (max-width: 768px) {

  .signUp__right .link {
    display: block;
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
  }

  .signUp__right .link a {
    color: #111;
  }

  .signUp__right-bottom button {
    margin-top: 0.5rem;
  }
}

@media (max-width: 568px) {
  .signUp__right form {
    gap: 0.5rem;
    text-align: center;
  width: 350px !important;
  margin: auto;
  }

  .signUp__right-bottom {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
    gap: 0.5rem;
  }
}
