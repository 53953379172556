.reach .card {
  flex: 1;
  background: #e9f6fc;
  height: 17.2rem;
  position: relative;
  perspective: 1000px;
  cursor: pointer;
  transition: ease-in-out 600ms;
}

.reach .card__front,
.card__back {
  overflow: hidden;
}

.reach .card__front{
  padding: 3rem 2rem;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  overflow: hidden;
}

.reach .card__back {
  padding: 1rem 1rem;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  overflow: hidden;
}

.reach .card__front:hover,
.reach .card__back:hover {
  transition: ease-in-out 900ms;
}

.card__front span {
  color: #606d83;
}

.card__back span {
  font-weight: 600;
  color: #071b3d;
}

.reach h5 {
  margin: 0.5rem 0 0;
  font-size: 2.2rem;
  font-weight: bold;
}

.reach .social__links {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  margin-top: 2rem;
}

.social__links svg {
  color: #14a800;
}

@media (max-width: 768px) {
  .reach .card__front,
  .reach .card__back {
    background-image: none;
  }
}

@media (max-width: 568px) {
  .reach h5 {
    font-size: 1.5rem;
  }

  .reach .card {
    height: 18.2rem;
    width: 100%;
  }
}
