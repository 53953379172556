.sidebar-bg{
background-color: #14a800;
}

.sidebar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.sidebar-nav_left{
  display: flex;
  align-items: center;
}


.menu-bars {
  /* margin-left: 2rem; */
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #14a800;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: all 0.5s ease-in-out;
  z-index: 999 !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.nav-menu.active {
  left: 0;
  transition: all 0.5s ease-in-out;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  font-weight: bold;
}

.nav-text a,
.admin {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.nav-text-admin {
  height: 35px;
}

.admin-link {
  height: 35px;
  margin-left: 2rem;
  color: #f5f5f5;
  cursor: pointer;
}

.admin-link span{
    font-size: .9rem;

}

.nav-text a:hover {
    color: #f5f5f5;

}

.nav-menu-items {
  width: 100%;
}

.sidebar-toggle {
  background-color: #14a800;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-menu span {
  margin-left: 16px;
}

.admin__icon {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}

.sidebar .btn-wrapper{
    display: flex;
    justify-content: flex-end;
}

.btn-wrapper a{
    text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
}